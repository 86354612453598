import "./StatusBar.scss"

const StatusBar = ({ step }) => {
  return (
    <div className="step-wrapper">
      <div className={`job-step ${step > 1 && "completed"}`}>
        <div className="indicator">1</div>
        <span>Overview</span>
      </div>
      <h1 className={`${step > 1 && "dash-completed"}`}> - </h1>
      <div className={`job-step ${step > 2 && "completed"}`}>
        <div className="indicator">2</div>
        <span>Job Description</span>
      </div>
      <h1 className={`${step > 3 && "dash-completed"}`}> - </h1>
      <div className={`job-step ${step > 3 && "completed"}`}>
        <div className="indicator">3</div>
        <span>Review Job</span>
      </div>
    </div>
  )
}
export default StatusBar
