import { useState } from "react"
import { createJob } from "api/jobs"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import Button from "components/shared/Buttons/Primary"
import Icon from "components/Icon"

import styles from "./StepNav.module.scss"

const StepNav = ({ setStep, step, setErrors, form, errors, job }) => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const firstStep = step === 1
  const secondStep = step === 2
  const thirdStep = step === 3

  const handleBack = () => {
    if (firstStep) {
      return
    } else {
      setStep(step - 1)
    }
  }

  const urlRegex =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

  function validateURL(url) {
    return urlRegex.test(url)
  }

  const nextText =
    thirdStep && job
      ? "Confirm Changes"
      : thirdStep
      ? "Approve & Post Job"
      : firstStep
      ? "See the Magic"
      : "Next"

  const validateFirstStep = () => {
    const errors = {}
    const cantBeBlank = ["title", "role", "location_type", "company"]

    for (let field of cantBeBlank) {
      if (form[field] === "" || form[field] === null) {
        errors[field] = ["Cannot be blank"]
      }
    }

    if (form.external_url && !validateURL(form.external_url)) {
      errors.external_url = [
        "Invalid URL. Must begin with `https://` or `http://`",
      ]
    }
    if (
      form.location_type &&
      form.location_type?.name !== "Fully remote" &&
      form?.metro_locations?.length === 0
    ) {
      errors.metro_locations = [
        "Your selection for Location Type requires at least one location to be selected",
      ]
    }
    if (parseInt(form.ote_min) > parseInt(form.ote_max)) {
      errors.ote_max = ["OTE Max must be greater than OTE Min"]
    }

    return errors
  }

  const handleNext = () => {
    if (secondStep) {
      const errors = validateFirstStep()
      if (Object.keys(errors).length > 0 || !form.description) {
        setErrors(errors)
        if (!form.description)
          setErrors({ ...errors, description: ["Must not be blank."] })
      } else {
        setStep(step + 1)
      }
    } else {
      setStep(step + 1)
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    const {
      sales_division,
      years_of_experience,
      metro_locations,
      location_type,
      role,
      ote_max,
      ote_min,
      external_url,
      description,
      title,
      company,
      seeded,
      open_to_new,
      featured,
    } = form

    const submission = {
      company_id: company?.id,
      role_id: role?.id,
      metro_location_ids: metro_locations?.map(location => location.id),
      years_of_experience: years_of_experience?.name,
      sales_division: sales_division?.name,
      location_type: location_type?.name,
      ote_max,
      ote_min,
      external_url,
      description,
      title,
      seeded,
      open_to_new,
      featured,
      status: "Active",
    }

    createJob(submission)
      .then(() => {
        toast.success("Job Created!")

        navigate("/jobs")
      })
      .catch(error =>
        toast.error(error?.data?.base?.[0] || "Something went wrong.")
      )
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.container}>
      <Button
        variant="outline"
        onClick={firstStep ? () => navigate("/jobs") : handleBack}
        size="medium"
        disabled={loading}
      >
        {firstStep ? (
          <Icon name="close" height="16" width="16" />
        ) : (
          <Icon name="arrowLeft" height="12" width="12" />
        )}
        {firstStep
          ? "Cancel"
          : secondStep
          ? "Go Back and try a new link"
          : "Go Back"}
      </Button>
      <Button
        onClick={thirdStep ? handleSubmit : handleNext}
        size="medium"
        data-cy="new-job-proceed"
        disabled={loading}
      >
        {nextText}
        <Icon
          name="arrowLeft"
          height="12"
          width="12"
          className={styles.arrow}
        />
      </Button>
    </div>
  )
}

export default StepNav
