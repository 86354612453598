export const difference = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0

    // Replace _.transform with Object.keys().reduce()
    return Object.keys(newObj).reduce(
      (result, key) => {
        const value = newObj[key]

        // Replace _.isEqual with deep equality comparison
        if (!isEqual(value, origObj[key])) {
          // Replace _.isArray with Array.isArray
          const resultKey = Array.isArray(origObj) ? arrayIndexCounter++ : key

          // Replace _.isObject with type checking
          result[resultKey] =
            isObject(value) && isObject(origObj[key])
              ? changes(value, origObj[key])
              : value
        }
        return result
      },
      Array.isArray(newObj) ? [] : {}
    )
  }

  // Helper function to replace _.isObject
  function isObject(item) {
    return item !== null && typeof item === "object"
  }

  // Helper function to replace _.isEqual
  function isEqual(value1, value2) {
    // Handle primitive types
    if (value1 === value2) return true

    // Handle null/undefined
    if (!value1 || !value2) return value1 === value2

    // Handle different types
    if (typeof value1 !== typeof value2) return false

    // Handle arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length) return false
      return value1.every((val, index) => isEqual(val, value2[index]))
    }

    // Handle objects
    if (isObject(value1) && isObject(value2)) {
      const keys1 = Object.keys(value1)
      const keys2 = Object.keys(value2)

      if (keys1.length !== keys2.length) return false
      return keys1.every(key => isEqual(value1[key], value2[key]))
    }

    return false
  }

  return changes(newObj, origObj)
}
