import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import {
  getQuestion,
  publishQuestion,
  ignoreQuestion,
  updateQuestion,
} from "api/questions"
import { formatDateTime } from "utils/format"
import { getOrdinal } from "utils/string"

import { Button, Offcanvas, Tabs, Tab } from "react-bootstrap"
import DetailSlideout from "components/shared/DetailSlideout"
import StatusBadge from "./StatusBadge"
import GridUser from "components/users/GridUser"
import Icon from "components/Icon"
import Answer from "./Answer"
import IgnoreModal from "./IgnoreModal"
import Audits from "components/shared/Audits"
import Flags from "components/shared/Flags"
import ApprovalActions from "components/approvals/Actions"
import ApprovalsList from "components/approvals/List"

import "./Detail.scss"

const QuestionDetail = ({ id, show, handleClose, handleGridUpdate }) => {
  const [question, setQuestion] = useState({})
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(false)
  const [newBody, setNewBody] = useState("")
  const [showIgnoreModal, setShowIgnoreModal] = useState(false)
  const [activeTab, setActiveTab] = useState("details")

  useEffect(() => {
    if (id) {
      getQuestion(id)
        .then(response => {
          setQuestion(response.data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [id])

  const handlePublish = () => {
    publishQuestion(id)
      .then(response => {
        setQuestion(response.data)
        handleGridUpdate(response.data)
        toast.success("Question published")
      })
      .catch(() => {
        // setLoading(false)
      })
  }

  const handleApprovalSuccess = data => {
    setQuestion(data)
    handleGridUpdate(data)
  }

  const handleIgnore = reason => {
    ignoreQuestion(id, reason)
      .then(response => {
        setQuestion(response.data)
        setShowIgnoreModal(false)
        handleGridUpdate(response.data)
        toast.success("Question ignored")
      })
      .catch(() => {
        // setLoading(false)
      })
  }

  const handleAnswerChange = (answer, parentAnswerId) => {
    const newQuestion = { ...question }

    if (parentAnswerId) {
      // Find the parent answer and update its descendant answer
      const parentAnswer = newQuestion.answers.find(
        a => a.id === parentAnswerId
      )
      if (parentAnswer) {
        const index = parentAnswer.descendant_answers.findIndex(
          a => a.id === answer.id
        )
        if (index !== -1) {
          parentAnswer.descendant_answers[index] = answer
        }
      }
    } else {
      // Update top-level answer
      const index = newQuestion.answers.findIndex(a => a.id === answer.id)
      if (index !== -1) {
        newQuestion.answers[index] = answer
      }
    }

    setQuestion(newQuestion)
  }

  const handleHide = () => {
    setEditing(false)
    setNewBody(question.body)
    handleClose()
  }

  const {
    company,
    user,
    answers,
    content_categories,
    answers_count,
    status,
    created_at,
    published_at,
    type,
    audits,
    flags,
    approvals,
    ready_for_publish,
  } = question

  const { current_company_metric_scores } = company || []

  const handleUpdate = () => {
    updateQuestion(id, { question: { body: newBody } })
      .then(res => {
        if (res) {
          toast.success("Question updated successfully!")
        }
        setQuestion(res.data)
        setEditing(false)
      })
      .catch(() => {
        toast.error("Something went wrong.")
        setError(true)
      })
  }

  useEffect(() => {
    if (question?.body) {
      setNewBody(question.body)
    }
  }, [question])

  const showPublishButton =
    status !== "Published" && ready_for_publish && !editing

  if (loading) {
    return <div />
  }

  const askedByRepVue = type === "Asked by RepVue"

  return (
    <>
      <DetailSlideout
        show={show}
        onHide={handleHide}
        placement="end"
        onExited={() => {
          setEditing(false)
          setActiveTab("details")
          setLoading(true)
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            Question Detail
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Tabs
            id="rating-tabs"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            className="mb-2"
            transition={false}
          >
            <Tab eventKey="details" title="Details">
              <div className="detail-body">
                <Flags flags={flags} />
                <section>
                  <div className="section-header">
                    <label>Status</label>
                    <StatusBadge status={status} />
                  </div>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="user" /> User
                    </label>
                    {user?.id ? (
                      <GridUser user={user} />
                    ) : (
                      <img
                        src={askedByRepVue ? "/repvue.svg" : "/chatgpt.svg"}
                        alt={askedByRepVue ? "RepVue" : "ChatGPT"}
                        height="24"
                        width="24"
                      />
                    )}
                  </div>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="company" /> Company
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td colSpan={2}>Name</td>
                        <td>
                          <Link
                            to={`/companies/${company?.id}`}
                            className="ms-1"
                          >
                            {company?.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>RepVue Score</td>
                        <td>{company?.repvue_score.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>User Count</td>
                        <td>{company?.distinct_raters_count}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Last Question Date</td>
                        <td>{formatDateTime(company?.last_question_date)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="category-table">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th className="text-end w-25">Score</th>
                        <th className="text-end w-25">Industry Rank(%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {current_company_metric_scores.map(metric => {
                        const {
                          id,
                          name,
                          raw_average,
                          company_metric_percentile,
                        } = metric
                        const percentile = company_metric_percentile
                          ? `${company_metric_percentile}${getOrdinal(
                              company_metric_percentile
                            )}`
                          : String.fromCharCode(8212)

                        return (
                          <tr key={id}>
                            <td>{name}</td>
                            <td className="text-end">{raw_average}</td>
                            <td className="text-end">{percentile}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </section>
                <section>
                  <div className="section-header">
                    <label>
                      <Icon name="info" /> About
                    </label>
                  </div>
                  <table className="company-table">
                    <tbody>
                      <tr>
                        <td>Categories</td>
                        <td>
                          {content_categories.map(category => (
                            <span
                              key={category.id}
                              className="badge bg-primary me-1"
                            >
                              {category.category}
                            </span>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Created</td>
                        <td>{formatDateTime(created_at)}</td>
                      </tr>
                      <tr>
                        <td>Published</td>
                        <td>{formatDateTime(published_at)}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                {approvals?.length > 0 && (
                  <ApprovalsList approvals={approvals} />
                )}
                {!editing ? (
                  <section className="question-text">
                    <p>{newBody}</p>
                  </section>
                ) : (
                  <>
                    <textarea
                      className={`form-control ${error ? "form-error" : ""}`}
                      rows="4"
                      onChange={event => setNewBody(event.target.value)}
                      value={newBody}
                    />
                    {error && (
                      <div className="error-message">Something went wrong.</div>
                    )}
                  </>
                )}
                <section>
                  <label>{`Answers (${answers_count})`}</label>
                  {answers.length > 0 ? (
                    <>
                      {answers.map(answer => (
                        <Answer
                          key={answer.id}
                          answer={answer}
                          handleAnswerChange={handleAnswerChange}
                        />
                      ))}
                    </>
                  ) : (
                    <p>
                      <em>No answers yet.</em>
                    </p>
                  )}
                </section>
              </div>
              <div className="detail-footer">
                <ApprovalActions
                  contentId={id}
                  contentType="question"
                  status={status}
                  ready_for_publish={ready_for_publish}
                  approvals={approvals}
                  editing={editing}
                  handleSuccess={handleApprovalSuccess}
                >
                  {showPublishButton && (
                    <Button
                      variant="success"
                      className="w-100"
                      onClick={handlePublish}
                    >
                      Publish
                    </Button>
                  )}
                  {!user?.id && !editing && approvals?.length === 0 && (
                    <Button
                      variant="warning"
                      className="w-100"
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </Button>
                  )}
                  {status !== "Ignored" && !editing && (
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={() => setShowIgnoreModal(true)}
                    >
                      Ignore
                    </Button>
                  )}
                  {editing && (
                    <>
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                      <Button
                        variant="danger"
                        className="w-100"
                        onClick={() => {
                          setEditing(false)
                          setError(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </ApprovalActions>
              </div>
            </Tab>
            <Tab
              eventKey="audits"
              title={
                <>
                  Audits
                  {audits.length > 0 && (
                    <span className="badge rounded-pill bg-secondary">
                      {audits.length}
                    </span>
                  )}
                </>
              }
            >
              <Audits audits={audits} />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </DetailSlideout>
      <IgnoreModal
        show={showIgnoreModal}
        data={question}
        handleClose={() => setShowIgnoreModal(false)}
        handleSubmit={handleIgnore}
        type="question"
      />
    </>
  )
}

export default QuestionDetail
