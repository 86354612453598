import { Link } from "react-router-dom"
import Icon from "components/Icon"

const UserNotFound = () => {
  return (
    <div className="not-found d-flex flex-column align-items-center justify-content-center h-100">
      <Icon name="user" className="mb-3" />
      <h4>User not found</h4>
      <Link to="/users">Back to users page</Link>
    </div>
  )
}

export default UserNotFound
