import apiCall from "utils/api"

export const publishAnswer = id => {
  return apiCall("post", `answers/${id}/publish`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const ignoreAnswer = (id, params) => {
  const options = { data: params }
  return apiCall("post", `answers/${id}/ignore`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateAnswer = (id, params) => {
  const options = { data: { answer: params } }
  return apiCall("patch", `answers/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
