export const capitalizeString = str =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 *  getOrdinal
 * @param {Number} n numerical value
 * @returns
 */
export function getOrdinal(n) {
  let ord = "th"

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st"
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd"
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd"
  }

  return ord
}

export const htmlStripper = html => {
  return html.replace(/(\\r\\n|\\n|\\r\|)|(<iframe.*?\/iframe>)/gm, "")
}
