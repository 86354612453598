import { useState, useEffect } from "react"
import styles from "./Messages.loader.module.scss"

const LoaderMessages = () => {
  const phrases = [
    "Loading magic...",
    "Casting spells...",
    "Brewing potions...",
    "Consulting the grimoire...",
    "Gathering secret ingredients...",
    "Fine-tuning enchantments...",
    "Summoning mystical forces...",
    "Unlocking ancient secrets...",
    "Weaving spells of wonder...",
    "Enchanting the surroundings...",
    "Stirring the cauldron of dreams...",
    "Chanting arcane incantations...",
    "Preparing the wizard's brew...",
    "Drawing runes of power...",
    "Polishing the magic wand...",
    "Illuminating the path to wisdom...",
  ]

  const [currentPhrase, setCurrentPhrase] = useState(phrases[0])
  const [fadeIn, setFadeIn] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false)

      setTimeout(() => {
        setCurrentPhrase(phrases[Math.floor(Math.random() * phrases.length)])
        setFadeIn(true)
      }, 500)
    }, 3000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.loaderMessage}>
      <p className={fadeIn ? styles.fadeIn : styles.fadeOut}>{currentPhrase}</p>
    </div>
  )
}

export default LoaderMessages
