import { useState, useEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import InitialStep from "components/jobs/Steps/Initial"
import StepTwoForm from "components/jobs/Steps/StepTwoForm"
import NewJobSummary from "components/jobs/Steps/Summary/NewJobSummary"
import StepNav from "components/jobs/StepNav"
import Header from "components/Header"

import "./New.scss"

const NewJobRoute = () => {
  const newJobContainerRef = useRef(null)
  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState([])
  const [form, setForm] = useState({
    title: "",
    location_type: null,
    role: null,
    ote_min: undefined,
    ote_max: undefined,
    open_to_new: false,
    metro_locations: [],
    sales_division: null,
    years_of_experience: null,
    external_url: null,
    description: null,
    url_magic: null,
    company: null,
    seeded: true,
    featured: false,
  })

  const setDescription = value => {
    setForm({
      ...form,
      description: value,
    })
  }

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const scrollToElement = (elementId, smooth = true) => {
    const element = document.getElementById(elementId)
    if (element)
      element.scrollIntoView({
        behavior: smooth ? "smooth" : "auto",
        block: "start",
      })
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToElement("new-job-container", false)
    }, 10)
  }, [step])

  return (
    <>
      <Header title="New Job" icon="job" className="create-job" />
      <div className="new-job" id="new-job-container">
        <Container fluid>
          <Row>
            <Col>
              <div ref={newJobContainerRef}>
                {step === 1 && (
                  <InitialStep
                    getErrors={getErrors}
                    setStep={setStep}
                    setForm={setForm}
                    form={form}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}

                {step === 2 && (
                  <StepTwoForm
                    getErrors={getErrors}
                    setForm={setForm}
                    form={form}
                    errors={errors}
                    setDescription={setDescription}
                    description={form.description}
                  />
                )}

                {step === 3 && (
                  <Row>
                    <Col lg={{ offset: 2, span: 8 }}>
                      <NewJobSummary setStep={setStep} form={form} />
                    </Col>
                  </Row>
                )}

                {step > 1 && (
                  <StepNav
                    step={step}
                    setStep={setStep}
                    setErrors={setErrors}
                    form={form}
                    errors={errors}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NewJobRoute
