import shapes from "assets/shapes.svg"
import styles from "./banner.summary.module.scss"

const BannerSummary = ({ url, label }) => {
  return (
    <article className={styles.banner}>
      <img src={shapes} alt="shapes" className={styles.banner__shapes} />
      <span>{label}&nbsp;</span>
      {url && (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )}
    </article>
  )
}

export default BannerSummary
