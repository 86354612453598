import classNames from "classnames"
import { htmlStripper } from "utils/string"
import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"
import styles from "./description.form.module.scss"

const StepTwo = ({
  setDescription,
  description,
  errors,
  isEditingAndIntegrated,
}) => {
  const strippedDescription = description && htmlStripper(description)

  const onChange = value => {
    setDescription(value)
    delete errors["description"]
  }

  return (
    <div
      className={classNames(styles.form, {
        [styles.error]: errors.description,
      })}
    >
      <label htmlFor="description">Tell us more about the job</label>
      {isEditingAndIntegrated && (
        <em className={styles.subtext}>
          We ingest the job description from your ATS. Please make changes there
          if you wish to update the job listing description.
        </em>
      )}
      <div className={styles.wrapper}>
        {isEditingAndIntegrated ? (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strippedDescription || "",
            }}
          />
        ) : (
          <ReactQuill
            theme="snow"
            value={description}
            onChange={value => {
              onChange(value)
            }}
            data-cy="new-job-description"
          />
        )}
      </div>
      {errors.description && (
        <p className={styles.error__message}>Description must not be blank.</p>
      )}
    </div>
  )
}

export default StepTwo
