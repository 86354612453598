import Modal from "react-bootstrap/Modal"

function rvModal({ size, title, show, handleClose, children }) {
  return (
    <Modal size={size} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

export default rvModal
