import { useState } from "react"

import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"
import "./StepTwo.scss"

const StepTwo = ({ setStep, setDescription, description }) => {
  const [error, setError] = useState(false)
  const onSubmit = e => {
    e.preventDefault()
    if (!description) {
      setError(true)
    } else {
      setStep(3)
    }
  }
  return (
    <form className={`${error && "description-error"}`}>
      <h4>Job Description</h4>
      <div className="description-wrapper">
        <ReactQuill
          theme="snow"
          value={description}
          onChange={value => {
            setDescription(value)
          }}
        />
      </div>
      {error && <p className="error-message">Description must not be blank.</p>}
      <button
        className="btn btn-primary"
        type="submit"
        onClick={e => onSubmit(e)}
      >
        Review
      </button>
    </form>
  )
}
export default StepTwo
