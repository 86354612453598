import { useEffect } from "react"
import classNames from "classnames"
import { Container, Col, Row } from "react-bootstrap"
import Input from "components/forms/Input"
import { useForm } from "react-hook-form"
import RoleSelect from "components/roles/Select"
import EnumSelect from "components/jobs/EnumSelect"
import MetroLocationsSelect from "components/metro_locations/Select"
import CompaniesSelect from "components/companies/Select"
import styles from "./details.form.module.scss"

const StepOne = ({
  getErrors,
  setForm,
  form,
  errors,
  isEditingAndIntegrated,
}) => {
  const { register, control, watch } = useForm({
    defaultValues: {
      company: form?.company || null,
      metro_locations: form?.metro_locations || null,
    },
  })

  const watchedCompany = watch("company")
  const watchedMetroLocations = watch("metro_locations")

  const changeValue = (field, value) => {
    delete errors[field]
    setForm({ ...form, [field]: value })
  }

  useEffect(() => {
    if (watchedMetroLocations)
      changeValue("metro_locations", watchedMetroLocations)
    // eslint-disable-next-line
  }, [watchedMetroLocations])

  useEffect(() => {
    if (watchedCompany) changeValue("company", watchedCompany)
    // eslint-disable-next-line
  }, [watchedCompany])

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="title">Job Title</label>

            <Input
              type="text"
              name="title"
              label="Name"
              value={form?.title || ""}
              error={getErrors("title")[0]}
              register={register}
              onChange={e => changeValue("title", e?.target?.value)}
            />
            {isEditingAndIntegrated && (
              <em className={classNames(styles.subtext, "form-text")}>
                We ingest the job title from your ATS. Please make changes there
                if you wish to update the job listing title.
              </em>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="company">Company</label>

            <CompaniesSelect
              name="company"
              control={control}
              published
              value={form?.company}
              error={errors?.company?.[0]}
              wholeCompany
            />
          </div>

          <div className={`mb-3 ${errors?.role && "invalid-select"}`}>
            <label htmlFor="role" className="form-label">
              Role
            </label>
            <RoleSelect
              name="role"
              field="role"
              control={control}
              value={form?.role}
              onChange={e => changeValue("role", e)}
              error={errors?.role}
              className={errors?.role && "invalid-input"}
            />
            {errors?.role && (
              <p className="error-message">{errors.role?.[0]}</p>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="sales_division" className="form-label">
            Sales Division
          </label>
          <EnumSelect
            name="sales_division"
            field="sales_division"
            control={control}
            value={form?.sales_division}
            onChange={e => changeValue("sales_division", e)}
            error={getErrors("sales_division")[0]}
            className={errors?.sales_division?.[0] && "invalid-input"}
          />
          {errors?.sales_division && (
            <p className="error-message">{errors.sales_division?.[0]}</p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="years_of_experience" className="form-label">
            Work Experience
          </label>
          <EnumSelect
            name="years_of_experience"
            field="years_of_experience"
            value={form?.years_of_experience}
            onChange={e => changeValue("years_of_experience", e)}
            control={control}
            error={getErrors("years_of_experience")[0]}
            className={errors?.years_of_experience && "invalid-input"}
          />
          {errors?.years_of_experience && (
            <p className="error-message">{errors.years_of_experience}</p>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="external_url" className="form-label">
            External URL
          </label>
          <Input
            type="text"
            name="external_url"
            label="Name"
            value={form?.external_url || ""}
            onChange={e => changeValue("external_url", e?.target?.value)}
            error={getErrors("external_url")[0]}
            register={register}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="location_type" className="form-label">
            Location Type
          </label>

          <EnumSelect
            name="location_type"
            field="location_type"
            control={control}
            value={form?.location_type}
            error={errors?.location_type}
            className={errors?.location_type && "invalid-input"}
            onChange={e => changeValue("location_type", e)}
          />
          {errors?.location_type && (
            <p className="error-message">{errors.location_type?.[0]}</p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="external_url" className="form-label">
            Metro Locations
          </label>
          <MetroLocationsSelect
            name="metro_locations"
            control={control}
            value={form?.metro_locations}
            multi={true}
            error={errors?.metro_locations}
            className={errors?.metro_locations && "invalid-input"}
          />
        </div>
        <Container fluid="lg" className="g-0">
          <Row>
            <Col lg={6}>
              <div className="mb-1">
                <label htmlFor="ote_min" className="form-label">
                  OTE Min
                </label>

                <Input
                  type="number"
                  name="ote_min"
                  label="Name"
                  value={form?.ote_min || ""}
                  error={getErrors("ote_min")[0]}
                  register={register}
                  onChange={e => changeValue("ote_min", e?.target?.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-1">
                <label htmlFor="ote_max" className="form-label">
                  OTE Max
                </label>

                <Input
                  type="number"
                  name="ote_max"
                  label="Name"
                  value={form?.ote_max || ""}
                  error={getErrors("ote_max")[0]}
                  register={register}
                  onChange={e => changeValue("ote_max", e?.target?.value)}
                />
              </div>
            </Col>
            <p className={styles.subtext}>
              OTE is optional, but if not selected we will use estimated RepVue
              data.
            </p>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="d-flex mb-2">
                <div className="form-check w-50">
                  <input
                    id="open_to_new"
                    name="open_to_new"
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      changeValue("open_to_new", e?.target?.checked)
                    }
                    defaultChecked={form?.open_to_new}
                  />
                  <label htmlFor="open_to_new" className="form-check-label">
                    Open To New
                  </label>
                </div>
                <div className="form-check ms-4 w-50">
                  <input
                    id="featured"
                    name="featured"
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={form?.featured}
                    onChange={e => changeValue("featured", e?.target?.checked)}
                  />
                  <label htmlFor="featured" className="form-check-label">
                    Featured
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="form-check mb-3 w-50">
                <input
                  id="seeded"
                  name="seeded"
                  className="form-check-input"
                  type="checkbox"
                  onChange={e => changeValue("seeded", e?.target?.checked)}
                  defaultChecked={form?.seeded}
                />
                <label htmlFor="seeded" className="form-check-label">
                  Seeded
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default StepOne
