import apiCall from "utils/api"

export const createJobScraping = data => {
  const options = { data: { scraping: data } }
  return apiCall("post", `jobs/scraping`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getJobScraping = id => {
  return apiCall("get", `jobs/scraping/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
