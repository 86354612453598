import { Container, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useState, useMemo, useEffect } from "react"
import { Card } from "react-bootstrap"
import Input from "components/forms/Input"
import EnumSelect from "components/jobs/EnumSelect"
import MetroLocationsSelect from "components/metro_locations/Select"
import RoleSelect from "components/roles/Select"
const StepOne = ({ companyName, getErrors, setForm, setStep, form }) => {
  const {
    register,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()
  const [selectErrors, setSelectErrors] = useState({})
  const defaultValues = useMemo(
    () => ({
      title: form[1].title,
      role: form[1].role,
      metro_locations: form[1].metro_locations,
      featured: form[1].featured,
      open_to_new: form[1].open_to_new,
      location_type: form[1].location_type,
      ote_min: form[1].ote_min,
      ote_max: form[1].ote_max,
      external_url: form[1].external_url,
      seeded: form[1].seeded,
    }),
    [form]
  )
  const onSubmit = data => {
    if (data.role) {
      setForm({
        ...form,
        1: data,
      })
      setStep(2)
    } else {
      const selectCheck = {}
      if (!form[1].role) selectCheck.role = "This field is required."
      setSelectErrors(selectCheck)
    }
  }
  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  return (
    <>
      <h4>Overview</h4>
      <Card className="px-3 py-4 mt-3 form-card">
        <form onSubmit={handleSubmit(onSubmit)} className="form-step-one">
          <div className="mb-3">
            <label htmlFor="company" className="form-label">
              Company
            </label>
            <input
              type="text"
              name="company"
              label="Company"
              value={companyName}
              className="form-control"
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            {/* <input
              {...register("title", { required: true })}
              className={`form-control ${errors?.title && "invalid-input"}`}
              value={defaultValues.title}
            /> */}
            <Input
              type="text"
              name="title"
              label="Name"
              error={getErrors("title")[0]}
              register={register}
            />
          </div>
          <div
            className={`mb-3 ${
              selectErrors?.location_type && "invalid-select"
            }`}
          >
            <label htmlFor="location_type" className="form-label">
              Location Type
            </label>
            <EnumSelect
              name="location_type"
              field="location_type"
              control={control}
            />
          </div>
          <div className={`mb-3 ${selectErrors?.role && "invalid-select"}`}>
            <label htmlFor="role" className="form-label">
              Role
            </label>
            <RoleSelect
              name="role"
              field="role"
              control={control}
              error={getErrors("role")[0]}
              className={selectErrors?.role && "invalid-input"}
            />
            {selectErrors?.role && (
              <p className="error-message">{selectErrors.role}</p>
            )}
          </div>
          <div
            className={`mb-3 ${
              selectErrors?.sales_division && "invalid-select"
            }`}
          >
            <label htmlFor="sales_division" className="form-label">
              Sales Division
            </label>
            <EnumSelect
              name="sales_division"
              field="sales_division"
              control={control}
              error={getErrors("sales_division")[0]}
              className={selectErrors?.sales_division && "invalid-input"}
            />
            {selectErrors?.sales_division && (
              <p className="error-message">{selectErrors.sales_division}</p>
            )}
          </div>
          <div
            className={`mb-3 ${
              selectErrors?.years_of_experience && "invalid-select"
            }`}
          >
            <label htmlFor="years_of_experience" className="form-label">
              Years of Experience
            </label>
            <EnumSelect
              name="years_of_experience"
              field="years_of_experience"
              control={control}
              error={getErrors("years_of_experience")[0]}
              className={selectErrors?.years_of_experience && "invalid-input"}
            />
            {selectErrors?.years_of_experience && (
              <p className="error-message">
                {selectErrors.years_of_experience}
              </p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="external_url" className="form-label">
              External URL
            </label>
            <Input
              type="text"
              name="external_url"
              label="Name"
              error={getErrors("external_url")[0]}
              register={register}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="external_url" className="form-label">
              Metro Locations
            </label>
            <MetroLocationsSelect
              name="metro_locations"
              control={control}
              value={defaultValues.metro_locations}
              multi={true}
              error={getErrors("metro_locations")[0]}
              className={selectErrors?.metro_locations && "invalid-input"}
            />
            {selectErrors?.metro_locations && (
              <p className="error-message">{selectErrors.metro_locations}</p>
            )}
          </div>
          <Container fluid="lg" className="g-0">
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    OTE Min
                  </label>
                  <input
                    type="number"
                    placeholder={defaultValues.ote_min}
                    className="form-control"
                    {...register("ote_min")}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    OTE Max
                  </label>
                  <input
                    type="number"
                    placeholder={defaultValues.ote_max}
                    className={`form-control ${
                      errors?.ote_max && "invalid-input"
                    }`}
                    {...register("ote_max", {
                      validate: () =>
                        Number(getValues("ote_min")) <=
                        Number(getValues("ote_max")),
                    })}
                  />
                  {errors?.ote_max && (
                    <p className="error-message">
                      OTE Max must be greater than or equal to OTE Min.
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="d-flex mb-2">
                  <div className="form-check w-50">
                    <input
                      id="open_to_new"
                      name="open_to_new"
                      className="form-check-input"
                      type="checkbox"
                      {...register("open_to_new")}
                      defaultChecked={defaultValues.open_to_new}
                    />
                    <label htmlFor="open_to_new" className="form-check-label">
                      Open To New
                    </label>
                  </div>
                  <div className="form-check ms-4 w-50">
                    <input
                      id="featured"
                      name="featured"
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={defaultValues.featured}
                      {...register("featured")}
                    />
                    <label htmlFor="featured" className="form-check-label">
                      Featured
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="form-check mb-3 w-50">
                  <input
                    id="seeded"
                    name="seeded"
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={defaultValues.seeded}
                    {...register("seeded")}
                  />
                  <label htmlFor="seeded" className="form-check-label">
                    Seeded
                  </label>
                </div>
              </Col>
            </Row>
          </Container>
          <button className="btn btn-primary" type="submit">
            Next Step
          </button>
        </form>
      </Card>
    </>
  )
}
export default StepOne
