import cn from "classnames"
import LoaderMessages from "./Messages"
import bigR from "assets/BigR.svg"
import styles from "./Loader.animation.module.scss"

const LoaderAnimation = () => {
  return (
    <>
      <div className={styles.scene}>
        <div className={styles.objects}>
          <div className={styles.square}></div>
          <div className={styles.circle}></div>
          <div className={styles.triangle}></div>
        </div>
        <div className={styles.wizard}>
          <div className={styles.body}></div>
          <div className={styles.rightArm}>
            <div className={styles.rightHand}></div>
          </div>
          <div className={styles.leftArm}>
            <div className={styles.leftHand}></div>
          </div>
          <div className={styles.head}>
            <div className={styles.beard}></div>
            <div className={styles.face}>
              <div className={styles.adds}></div>
            </div>
            <div className={styles.hat}>
              <div className={styles.hatOfTheHat}></div>

              <div className={cn(styles.repvueLogo, styles.repvueLogo__second)}>
                <img src={bigR} alt="RepVue" className={styles.sidebar__bigR} />
              </div>

              <div
                className={cn(
                  styles.fourPointStar,
                  styles.fourPointStar__first
                )}
              ></div>

              <div
                className={cn(
                  styles.fourPointStar,
                  styles.fourPointStar__second
                )}
              ></div>

              <div
                className={cn(
                  styles.fourPointStar,
                  styles.fourPointStar__third
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <LoaderMessages />
    </>
  )
}

export default LoaderAnimation
