import { useEffect, useState, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import { updateJob, approveJob, rejectJob, closeJob } from "api/jobs"
import { formatDateTime, htmlStripper } from "utils/format"
import ReactQuill from "react-quill"

import MetroLocationsSelect from "components/metro_locations/Select"
import EmployersSelect from "components/employers/Select"
import EnumSelect from "components/jobs/EnumSelect"
import RoleSelect from "components/roles/Select"
import Input from "components/forms/Input"
import Icon from "components/Icon"

import "./Overview.scss"

const JobOverviewRoute = ({ job, setJob }) => {
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})

  const { register, handleSubmit, control, reset } = useForm()

  const defaultValues = useMemo(
    () => ({
      title: job?.title,
      description: job?.description,
      featured: job?.featured,
      status: job?.status,
      slug: job?.slug,
      role: job?.role?.id,
      sales_division: job?.sales_division,
      years_of_experience: job?.years_of_experience,
      location_type: job?.location_type,
      metro_locations: job?.metro_locations,
      employers: job?.employers,
      ote_min: job?.ote_min,
      ote_max: job?.ote_max,
      question: job?.question,
      external_url: job?.external_url,
      seeded: job?.seeded,
    }),
    [job]
  )

  const {
    id,
    company,
    customer,
    created_at,
    updated_at,
    approved_at,
    closed_at,
    description,
    featured,
    status,
    slug,
    title,
    role,
    location_type,
    metro_locations,
    employers,
    ote_min,
    ote_max,
    source,
    question,
    external_id,
    sales_division,
    years_of_experience,
    open_to_new,
    external_url,
    seeded,
  } = job

  const strippedDescription = description && htmlStripper(description)

  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    const metroLocationIds = data.metro_locations.map(location => location.id)
    data.metro_location_ids = metroLocationIds
    delete data.metro_locations

    const employerIds = data.employers.map(employer => employer.id)
    data.employer_ids = employerIds
    delete data.employers

    if (data.ote_min === "") data.ote_min = null
    if (data.ote_max === "") data.ote_max = null

    if (data.question === "") data.question = null

    if (data.role) {
      data.role_id = data.role
      delete data.role
    }

    updateJob(id, data)
      .then(response => {
        setJob(response.data)
        toast.success("Update successful")
        setEditing(false)
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const handleApprove = () => {
    approveJob(id)
      .then(response => {
        setJob(response.data)
        toast.success("Job approved!")
      })
      .catch(() => {})
  }

  const handleReject = () => {
    if (window.confirm("Are you sure you want to reject this job?")) {
      rejectJob(id)
        .then(response => {
          setJob(response.data)
          toast.success("Job rejected!")
        })
        .catch(() => {})
    }
  }

  const handleClose = () => {
    if (
      window.confirm(
        "Are you sure you want to close this job? A notification will be sent to the to the employer free tier customer."
      )
    ) {
      closeJob(id)
        .then(response => {
          setJob(response.data)
          toast.success("Job closed")
        })
        .catch()
    }
  }

  const cancelEditing = () => {
    setEditing(false)
    setErrors({})
    reset({ ...defaultValues })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  const StandardActions = () => (
    <>
      {!editing ? (
        <button className="btn btn-primary" onClick={() => setEditing(true)}>
          Edit
        </button>
      ) : (
        <div>
          <button className="btn btn-light me-2" onClick={cancelEditing}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit(handleUpdate)}
          >
            Save
          </button>
        </div>
      )}
    </>
  )

  const FreeTierActions = () => (
    <>
      {!approved_at && (
        <div className="d-flex">
          <button
            className="btn btn-success me-2"
            onClick={() => handleApprove()}
          >
            Approve
          </button>
          <button className="btn btn-danger" onClick={() => handleReject()}>
            Reject
          </button>
        </div>
      )}
      {approved_at && (
        <button
          variant="danger"
          className="btn btn-danger ms-2 fw-bold"
          onClick={handleClose}
        >
          Close
        </button>
      )}
    </>
  )

  return (
    <>
      <div className="tab-header">
        <h3>Overview</h3>
        {customer?.access_type !== "Free" ? (
          <StandardActions />
        ) : (
          <FreeTierActions />
        )}
      </div>
      <div className="tab-container">
        <div className="job-overview">
          <form className="job-form">
            <div className={`editable-table ${editing ? "editing" : ""}`}>
              <table className="table horizontal table-compressed">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <th>Company</th>
                    <td>
                      <div className="form-value">
                        <Link to={`/companies/${company?.id}`}>
                          {company?.name}
                        </Link>
                      </div>
                      <div className="form-field">
                        <Link to={`/companies/${company?.id}`}>
                          {company?.name}
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Title</th>
                    <td>
                      {editing && !source ? (
                        <div className="form-field">
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="title"
                            error={getErrors("title")[0]}
                            register={register}
                          />
                        </div>
                      ) : (
                        <div className={`${!source ? "form-value" : ""}`}>
                          {title}
                        </div>
                      )}
                    </td>
                  </tr>
                  {source && (
                    <>
                      <tr className="source-row">
                        <th>Partner</th>
                        <td className="partner">
                          <img
                            src={require(`assets/${source}.png`)}
                            alt={source}
                          />
                          <span className="text-capitalize">{source}</span>
                        </td>
                      </tr>
                      {source === "greenhouse" && (
                        <tr className="source-row">
                          <th>Job ID</th>
                          <td>
                            <span className="font-monospace">
                              {external_id?.identifier?.job_id}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr className="source-row">
                        <th>Job Post ID</th>
                        <td>
                          <span className="font-monospace">
                            {external_id?.identifier?.job_post_id}
                          </span>
                        </td>
                      </tr>
                      <tr className="source-row">
                        <th>Partner Location</th>
                        <td>
                          {external_id?.identifier?.location ||
                            String.fromCharCode(8212)}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <th>Status</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">{status}</div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            name="status"
                            field="status"
                            control={control}
                            error={getErrors("status")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {role ? role?.name : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <RoleSelect
                            name="role"
                            field="role"
                            control={control}
                            error={getErrors("role")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Sales Division</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {sales_division
                            ? sales_division
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            name="sales_division"
                            field="sales_division"
                            control={control}
                            error={getErrors("sales_division")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="align-center">Years of Experience</th>
                    <td>
                      <div className="form-value text-field">
                        {years_of_experience || String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <EnumSelect
                          name="years_of_experience"
                          field="years_of_experience"
                          control={control}
                          error={getErrors("years_of_experience")[0]}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Location Type</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {location_type
                            ? location_type
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            name="location_type"
                            field="location_type"
                            control={control}
                            error={getErrors("location_type")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Location(s)</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {metro_locations?.length > 0
                            ? metro_locations.map(location => (
                                <span
                                  key={location?.id}
                                  className="badge rounded-pill bg-primary me-2"
                                >
                                  {location?.name_with_state_code}
                                </span>
                              ))
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <MetroLocationsSelect
                            name="metro_locations"
                            value={metro_locations}
                            control={control}
                            multi={true}
                            error={getErrors("metro_locations")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Employer Contact(s)</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {employers?.length > 0
                            ? employers.map(location => (
                                <span
                                  key={location.name}
                                  className="badge rounded-pill bg-success me-2"
                                >
                                  {location.name}
                                </span>
                              ))
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <EmployersSelect
                            companyId={company?.id}
                            name="employers"
                            value={employers}
                            control={control}
                            multi={true}
                            error={getErrors("employers")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>OTE Min</th>
                    <td>
                      <div className="form-value text-field">
                        {ote_min
                          ? `$${ote_min?.toLocaleString()}`
                          : String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <Input
                            className="form-control-sm"
                            type="number"
                            name="ote_min"
                            error={getErrors("ote_min")[0]}
                            register={register}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>OTE Max</th>
                    <td>
                      <div className="form-value text-field">
                        {ote_max
                          ? `$${ote_max?.toLocaleString()}`
                          : String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <Input
                            className="form-control-sm"
                            type="number"
                            name="ote_max"
                            error={getErrors("ote_max")[0]}
                            register={register}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Slug</th>
                    <td>{slug}</td>
                  </tr>
                  <tr>
                    <th>Featured</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {featured ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={featured}
                              {...register("featured")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Open to New</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {open_to_new ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={open_to_new}
                              {...register("open_to_new")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Seeded</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {seeded ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={seeded}
                              {...register("seeded")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="align-top">Screening Question</th>
                    <td>
                      <div className="form-value text-field">
                        {question || String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <textarea
                          className="form-control form-control-sm"
                          rows="3"
                          {...register("question")}
                        />
                        <div className="form-text">
                          Should be a yes or no question
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>External URL</th>
                    <td>
                      {editing ? (
                        <div className="form-field">
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="external_url"
                            error={getErrors("external_url")[0]}
                            register={register}
                          />
                        </div>
                      ) : (
                        <div className="form-value">
                          {external_url ? (
                            <a
                              href={external_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {external_url}
                            </a>
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="align-top">Description</th>
                    <td>
                      {!editing ? (
                        <div
                          className="form-value text-field job-description"
                          dangerouslySetInnerHTML={{
                            __html: strippedDescription || "",
                          }}
                        ></div>
                      ) : (
                        <div className="form-field">
                          <div className="description-wrapper">
                            <Controller
                              control={control}
                              name="description"
                              render={({ field: { onChange, value } }) => (
                                <ReactQuill
                                  theme="snow"
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
          <br />
          <div className="dates">
            <h4>Dates</h4>
            <div className="editable-table">
              <table className="table horizontal table-compressed">
                <tbody>
                  <tr>
                    <th>Created</th>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                  <tr>
                    <th>Approved</th>
                    <td>{formatDateTime(approved_at)}</td>
                  </tr>
                  <tr>
                    <th>Updated</th>
                    <td>{formatDateTime(updated_at)}</td>
                  </tr>
                  <tr>
                    <th>Closed</th>
                    <td>{formatDateTime(closed_at)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobOverviewRoute
