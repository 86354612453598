import cn from "classnames"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { Button } from "react-bootstrap"
import { validateUrl } from "utils/validations"
import { createJobScraping, getJobScraping } from "api/jobs/scraping"
import Icon from "components/Icon"
import Input from "components/shared/Input"
import LoaderAnimation from "./Loader"
import styles from "./initialStep.module.scss"

const InitialStep = ({ form, setForm, setStep, setErrors, errors }) => {
  const [loading, setLoading] = useState(false)

  const onChange = e => {
    const { value, name } = e?.target
    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  const pollScrapingStatus = (scrapingId, interval = 3000) => {
    if (!scrapingId) return Promise.reject(new Error("scrapingId is required"))

    const poll = (resolve, reject) => {
      getJobScraping(scrapingId)
        .then(response => {
          const status = response?.data?.status
          if (status === "completed") {
            resolve(response?.data)
          } else {
            setTimeout(() => poll(resolve, reject), interval)
          }
        })
        .catch(error => {
          console.error("Error during polling:", error)
          reject(error)
        })
    }

    return new Promise(poll)
  }

  const fetchExternalJob = () => {
    setLoading(true)
    const payload = { external_url: form.url_magic }

    return createJobScraping(payload)
      .then(response => {
        const scrapingId = response?.data?.id
        if (!scrapingId) throw new Error("No scrapingId returned")
        return pollScrapingStatus(scrapingId)
      })
      .then(finalData => {
        const { output } = finalData

        setForm({
          ...form,
          company: output?.company_id
            ? { id: output?.company_id, name: output?.company_name }
            : null,
          title: output?.job_title ?? null,
          description: output?.description ?? null,
          role: output?.role ? { id: output.role, name: output.role } : null,
          location_type: output?.location_type
            ? { id: output.location_type, name: output.location_type }
            : null,
          years_of_experience: output?.years_experience
            ? { id: output.years_experience, name: output.years_experience }
            : null,
          external_url: output?.external_url ?? form.url_magic,
          ote_min: output?.ote_min ?? null,
          ote_max: output?.ote_max ?? null,
          metro_locations: output?.metro_locations
            ? output.metro_locations?.map(location => ({
                ...location,
                name_with_state_code: location?.name,
              }))
            : null,
          sales_division: output?.sales_division
            ? { id: output.sales_division, name: output.sales_division }
            : null,
          open_to_new: output?.open_to_new ?? null,
          seeded: true,
        })

        setErrors([])

        setStep(2)
      })
      .catch(error => {
        toast.error(
          "Oops! Our wizard couldn't conjure the data. Please try again while he recharges his magic wand."
        )
        console.error("Error during scraping process:", error)
        throw error
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = () => {
    if (!validateUrl(form.url_magic))
      return setErrors({ ...errors, url_magic: "Please enter a valid URL." })

    fetchExternalJob()
  }

  return (
    <section className={styles.initialStep}>
      <article
        className={cn(styles.initialStep__card, { [styles.loading]: loading })}
      >
        {loading ? (
          <article className={styles.initialStep__loading}>
            <LoaderAnimation />
          </article>
        ) : (
          <>
            <article className={styles.initialStep__header}>
              <h1>
                AI Job Scraper <sup>beta</sup>
              </h1>
              <p>
                Add a job URL and we will try to automatically pull the required
                fields.
              </p>
            </article>

            <article className={styles.initialStep__form}>
              <div className={styles.initialStep__form__row}>
                <Input
                  type="text"
                  name="url_magic"
                  placeholder="https://"
                  value={form?.url_magic || ""}
                  onChange={onChange}
                  error={errors?.url_magic}
                  dashed
                />
              </div>
            </article>

            <article className={styles.initialStep__action}>
              <Button onClick={onSubmit}>
                Submit{" "}
                <Icon
                  name="arrowRight"
                  height="16"
                  width="16"
                  className={styles.arrow}
                />
              </Button>
            </article>

            <article className={styles.initialStep__skip}>
              <Button onClick={() => setStep(2)} variant="link">
                Skip and Add Manually
              </Button>
            </article>
          </>
        )}
      </article>
    </section>
  )
}

export default InitialStep
