import { Link } from "react-router-dom"
import Icon from "components/Icon"

const CompanyNotFound = () => {
  return (
    <div className="not-found d-flex flex-column align-items-center justify-content-center h-100">
      <Icon name="company" className="mb-3" />
      <h4>Company not found</h4>
      <Link to="/companies">Back to companies page</Link>
    </div>
  )
}

export default CompanyNotFound
