import { Container, Row, Col } from "react-bootstrap"
import Details from "components/jobs/Forms/Details"
import Description from "components/jobs/Forms/Description"
import BannerSummary from "components/jobs/Steps/Summary/Banner"

import styles from "./stepTwo.module.scss"

const StepTwoForm = ({
  getErrors,
  setForm,
  form,
  errors,
  isEditing,
  isEditingAndIntegrated,
  setDescription,
}) => {
  return (
    <Container className="pt-4 pb-4">
      <Row>
        {form?.url_magic && (
          <article className={styles.stepTwo__banner}>
            <div className={styles.stepTwo__banner__container}>
              <BannerSummary label="Fields were populated with our Job Scraping AI. Please review carefully and make any edits before submitting." />
            </div>
          </article>
        )}

        <Col sm={{ offset: 2, span: 8 }}>
          <Details
            getErrors={getErrors}
            setForm={setForm}
            form={form}
            errors={errors}
            isEditing={isEditing}
            isEditingAndIntegrated={isEditingAndIntegrated}
          />

          <Description
            setDescription={setDescription}
            description={form.description}
            errors={errors}
            isEditingAndIntegrated={isEditingAndIntegrated}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default StepTwoForm
