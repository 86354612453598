import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getCompany } from "api/companies"
import { getCompanyAudits } from "api/companies"
import { Helmet } from "react-helmet"

import Header from "components/Header"
import Tabs from "components/Tabs"
import StatusBadge from "components/companies/StatusBadge"
import ProfileRoute from "routes/companies/Profile"
import RatingsRoute from "routes/companies/Ratings"
import LocationsRoute from "routes/companies/Locations"
import JobsRoute from "routes/companies/Jobs"
import QuestionsRoute from "routes/companies/Questions"
import HeadcountsRoute from "routes/companies/Headcounts"
import TechStackRoute from "routes/companies/TechStack"
import BenefitsRoute from "routes/companies/Benefits"
import CultureRoute from "routes/companies/Culture"
import ReviewsRoute from "routes/companies/Reviews"
import FinancialMetricsRoute from "routes/companies/FinancialMetrics"
import AuditLogRoute from "components/shared/AuditLog"
import CompanyNotFound from "./companies/NotFound"

import "./Company.scss"

const CompanyRoute = () => {
  const { id } = useParams()

  const [company, setCompany] = useState({})
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState({})

  const fetchCompany = id => {
    getCompany(id)
      .then(response => {
        setCompany(response.data)
        setLoading(false)
      })
      .catch(response => {
        if (response.status === 404) {
          setNotFound(true)
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchCompany(id)
  }, [id])

  const tabs = [
    {
      label: "Profile",
      hash: "#profile",
      component: <ProfileRoute company={company} setCompany={setCompany} />,
    },
    {
      label: "Ratings",
      hash: "#ratings",
      component: <RatingsRoute id={id} />,
    },
    {
      label: "Reviews",
      hash: "#reviews",
      component: <ReviewsRoute id={id} />,
    },
    {
      label: "Locations",
      hash: "#locations",
      component: <LocationsRoute id={id} />,
    },
    {
      label: "Jobs",
      hash: "#jobs",
      component: <JobsRoute id={id} />,
    },
    {
      label: "Questions",
      hash: "#questions",
      component: <QuestionsRoute id={id} />,
    },
    {
      label: "Headcounts",
      hash: "#headcounts",
      component: <HeadcountsRoute id={id} />,
    },
    {
      label: "Financial Metrics",
      hash: "#financial-metrics",
      component: <FinancialMetricsRoute id={id} />,
    },
    {
      label: "Benefits",
      hash: "#benefits",
      component: <BenefitsRoute id={id} />,
    },
    {
      label: "Culture",
      hash: "#Culture",
      component: <CultureRoute company={company} setCompany={setCompany} />,
    },
    {
      label: "Tech Stack",
      hash: "#tech-stack",
      component: <TechStackRoute id={id} />,
    },
    {
      label: "Audit Log",
      hash: "#audit-log",
      component: <AuditLogRoute id={id} getterFunction={getCompanyAudits} />,
    },
  ]

  if (loading) return <div />
  if (notFound) return <CompanyNotFound />

  const showRepvueScore = company?.repvue_score && company?.repvue_score > 0

  const pageTitle = `${company.name} | RepVue Admin`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <Header title={company.name} icon="company">
        <div className="d-flex align-items-center">
          {showRepvueScore ? (
            <>
              <label>RepVue Score</label>
              <span className="badge repvue-score mx-2">
                {company.repvue_score.toFixed(2)}
              </span>
            </>
          ) : null}
          <StatusBadge status={company.status} />
          {company.published_status === "Partial" && (
            <div className="company-status-badge badge bg-warning text-dark ms-2">
              Partial
            </div>
          )}
        </div>
      </Header>
      <div className="company content">
        <Tabs tabs={tabs} defaultTab="profile" />
      </div>
    </>
  )
}

export default CompanyRoute
