import { useState } from "react"
import { ignoreAnswer, publishAnswer, updateAnswer } from "api/answers"
import toast from "react-hot-toast"
import { formatDateTime } from "utils/format"

import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import StatusBadge from "./StatusBadge"
import Icon from "components/Icon"
import IgnoreModal from "./IgnoreModal"
import Flags from "components/shared/Flags"
import Audits from "components/shared/Audits"
import ApprovalActions from "components/approvals/Actions"
import ApprovalList from "components/approvals/List"

import "./Answer.scss"

const Answer = ({ answer, handleAnswerChange, reply }) => {
  const [showIgnoreModal, setShowIgnoreModal] = useState(false)
  const [showAudits, setShowAudits] = useState(false)
  const [loading, setLoading] = useState(false)

  const handlePublish = () => {
    setLoading(true)
    publishAnswer(id)
      .then(response => {
        handleAnswerChange(response.data, answer?.parent_answer_id)
        toast.success(`${reply ? "Reply" : "Answer"} published!`)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        toast.error("Something went wrong")
      })
  }

  const handleIgnore = reason => {
    ignoreAnswer(id, reason)
      .then(response => {
        handleAnswerChange(response.data, answer?.parent_answer_id)
        setShowIgnoreModal(false)
        toast.success(`${reply ? "Reply" : "Answer"} ignored`)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        toast.error("Something went wrong")
      })
  }

  const setInsightful = value => {
    updateAnswer(id, { insightful: value }).then(response => {
      handleAnswerChange(response.data, answer?.parent_answer_id)
      toast.success("Answer updated")
    })
  }

  const handleApprovalSuccess = data => {
    handleAnswerChange(data, answer?.parent_answer_id)
  }

  const {
    id,
    author,
    author_type,
    body,
    status,
    flags,
    created_at,
    audits,
    ready_for_publish,
    approvals,
    user_status,
    descendant_answers,
    insightful,
  } = answer

  const url =
    author_type === "User" ? `/users/${author?.id}` : `/employers/${author?.id}`

  const showPublishButton = status !== "Published" && ready_for_publish

  if (answer?.parent_answer_id && !reply) return

  return (
    <>
      <div key={id} className={`${reply ? "reply" : "answer"}`}>
        <div className="answer-header">
          <div className="answer-author">
            <Icon name="user" />
            <Link to={url} className="ms-2">
              {author?.name}
            </Link>
            {author_type === "Employer" ? (
              <small> [employer]</small>
            ) : (
              user_status && (
                <small>
                  {" "}
                  [
                  {user_status === "current_employee"
                    ? `Current Employee`
                    : `Former Employee`}
                  ]
                </small>
              )
            )}
            <span className="answer-date">{formatDateTime(created_at)}</span>
          </div>
          <StatusBadge status={status} />
        </div>
        <Flags flags={flags} />
        <div className={`answer-body ${insightful ? "glow" : ""}`}>
          <p>{body}</p>
          <div className="d-flex justify-content-end">
            <div className="form-check form-switch">
              <input
                onChange={e => setInsightful(e.target.checked)}
                className="form-check-input"
                type="checkbox"
                role="switch"
                defaultChecked={insightful}
              />
              <span className="form-check-label" htmlFor="insightful">
                Insightful
              </span>
            </div>
          </div>
        </div>

        {approvals?.length > 0 && <ApprovalList approvals={approvals} />}
        <div className="answer-footer">
          <ApprovalActions
            contentId={id}
            contentType="answer"
            status={status}
            ready_for_publish={ready_for_publish}
            approvals={approvals}
            handleSuccess={handleApprovalSuccess}
          >
            {showPublishButton && (
              <Button
                variant="success"
                size="sm"
                onClick={handlePublish}
                disabled={loading}
              >
                Publish
              </Button>
            )}
            {status !== "Ignored" && (
              <Button
                variant="danger"
                size="sm"
                onClick={() => setShowIgnoreModal(true)}
              >
                Ignore
              </Button>
            )}
            {audits?.length > 0 && (
              <span
                onClick={() => setShowAudits(!showAudits)}
                className={`answer-audits-button ${showAudits && "active"}`}
              >
                Audits
                <Icon name="chevronDown" />
              </span>
            )}
          </ApprovalActions>
        </div>
        {audits?.length > 0 && (
          <div
            className={`mt-3 answer-audits ${showAudits ? "show" : "hidden"}`}
          >
            <Audits audits={audits} />
          </div>
        )}
        {descendant_answers?.length > 0 && (
          <div className="replies">
            {descendant_answers.map(answer => (
              <Answer
                key={answer.id}
                answer={answer}
                handleAnswerChange={handleAnswerChange}
                reply
              />
            ))}
          </div>
        )}
      </div>
      <IgnoreModal
        show={showIgnoreModal}
        handleClose={() => setShowIgnoreModal(false)}
        data={answer}
        handleSubmit={handleIgnore}
        type={reply ? "reply" : "answer"}
      />
    </>
  )
}

export default Answer
