import apiCall from "utils/api"

export const getJobs = params => {
  const options = { params }
  return apiCall("get", `jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getIgnoredJobs = params => {
  const options = { params }
  return apiCall("get", `jobs/ignored`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getJob = id => {
  return apiCall("get", `jobs/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const updateJob = (id, params) => {
  const options = { data: { job: params } }
  return apiCall("patch", `jobs/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const closeJob = id => {
  return apiCall("patch", `jobs/${id}/close`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getJobOptions = field => {
  const options = { params: { field: field } }
  return apiCall("get", `jobs/form_options`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const getJobAudits = id => {
  return apiCall("get", `jobs/${id}/audits`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const createJob = async data => {
  const options = { data: { job: data } }
  return apiCall("post", `/jobs`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const scrapeJob = data => {
  const options = { data: { scraping: data } }
  return apiCall("post", `jobs/scraping`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const approveJob = id => {
  return apiCall("patch", `jobs/${id}/approve`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

export const rejectJob = id => {
  return apiCall("patch", `jobs/${id}/reject`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
