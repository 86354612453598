import { useEffect, useState, useCallback } from "react"
import { debounce } from "utils/common"

import { Controller } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getMetroLocations } from "api/metro_locations"
import { customStyles } from "components/forms/reactSelectStyles"

import TypeBadge from "components/metro_locations/TypeBadge"

const MetroLocationsSelect = ({
  className,
  name,
  label,
  error,
  value,
  types,
  multi,
  ...rest
}) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(value)
  }, [value])

  const getOptions = useCallback(
    inputValue => {
      return getMetroLocations({ q: inputValue, types: types }).then(
        response => response.data.rows
      )
    },
    [types]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetOptions = useCallback(
    debounce((inputValue, callback) => {
      getOptions(inputValue)
        .then(callback)
        .catch(error => callback([]))
    }, false),
    [getOptions]
  )

  const loadOptions = useCallback(
    (inputValue, callback) => {
      if (!inputValue) {
        getOptions(inputValue).then(callback)
      } else {
        debouncedGetOptions(inputValue, callback)
      }
    },
    [getOptions, debouncedGetOptions]
  )

  const formatOptionLabel = (option, { context }) => {
    const { name_with_state_code, type } = option
    const showBadge = type && context === "menu"
    return (
      <div className="location-option d-flex align-items-center justify-content-between">
        <div>{name_with_state_code}</div>
        {showBadge && <TypeBadge type={type} />}
      </div>
    )
  }

  return (
    <>
      <Controller
        name={name}
        {...rest}
        render={({ field: { onChange } }) => {
          return (
            <AsyncSelect
              isMulti={multi}
              styles={customStyles}
              className={`${error ? "is-invalid" : ""}`}
              aria-label={label}
              defaultOptions
              getOptionValue={option => option.id}
              getOptionLabel={option =>
                option.name_with_state_code || option.name
              }
              loadOptions={loadOptions}
              formatOptionLabel={formatOptionLabel}
              defaultValue={selected}
              value={selected}
              onChange={value => {
                onChange(name === "metro_locations" ? value : value.id)
                setSelected(value)
              }}
            />
          )
        }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}

export default MetroLocationsSelect
