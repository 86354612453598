import { useState, useEffect, useRef } from "react"
import { getDashboardChart } from "api/dashboard"
import {
  Chart as ChartJS,
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { sumBy } from "utils/array"

import { CSVLink } from "react-csv"
import Card from "react-bootstrap/Card"
import { Bar } from "react-chartjs-2"
import DateRange from "./ChartControls/DateRange"

import "./Chart.scss"

const DailyRatings = () => {
  ChartJS.register(
    CategoryScale,
    Legend,
    LinearScale,
    BarElement,
    Title,
    Tooltip
  )
  dayjs.extend(utc)

  const DATE_FORMAT = "YYYY-MM-DD"

  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "month").format(DATE_FORMAT)
  )
  const [endDate, setEndDate] = useState(dayjs().format(DATE_FORMAT))
  const [interval, setInterval] = useState("day")
  const [loading, setLoading] = useState(true)

  const chartRef = useRef(null)

  const fetchChart = async params => {
    getDashboardChart(params)
      .then(response => {
        setData(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const updateChart = (start = startDate, end = endDate) => {
    const params = {
      start_date: start,
      end_date: end,
      interval,
    }

    setStartDate(start)
    setEndDate(end)

    fetchChart(params)
  }

  const handleRangeChange = (start, end) => {
    updateChart(start, end)
  }

  // const handleBarClick = e => {
  //   const el = getElementAtEvent(chartRef.current, e)

  //   const datasetIndex = el[0]?.datasetIndex
  //   if(datasetIndex !== 0) return // if not high value ratings, do nothing

  //   const index = el[0].index
  //   const date = data[index].date
  // }

  useEffect(() => {
    updateChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval])

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  }

  const upperCase = string => {
    return string[0].toUpperCase() + string.substring(1)
  }

  const getDatasets = () => {
    let dataset = [
      {
        data: data.map(day => day.high_value_ratings_count),
        label: "High Value Ratings",
        backgroundColor: "rgba(1, 58, 75, 0.75)",
        stack: "Ratings",
      },
      {
        data: data.map(day => day.ratings_count),
        label: "Ratings",
        backgroundColor: "rgba(2, 116, 151, 0.75)",
        stack: "Ratings",
      },
      {
        data: data.map(day => day.users_count),
        label: "Users",
        backgroundColor: "rgba(243, 123, 0, 0.75)",
        stack: "Users",
      },
    ]

    return dataset
  }

  const ratingsData = {
    labels:
      interval === "day"
        ? data.map(day => dayjs.utc(day.date).format("MMM D"))
        : interval === "week"
        ? data.map(day => "Week of " + dayjs.utc(day.date).format("MMM D"))
        : data.map(day => dayjs.utc(day.date).format("MMM")),
    datasets: getDatasets(),
  }

  const ratingsCount = sumBy(data, "ratings_count")?.toLocaleString()
  const usersCount = sumBy(data, "users_count")?.toLocaleString()

  if (loading) return <div />

  return (
    <div className="dashboard-chart-container">
      <Card>
        <div className="dashboard-chart">
          <div className="dashboard-chart-header">
            <h5 id="dashboard-title">{`Ratings & Users by ${upperCase(
              interval
            )}`}</h5>
            <ul className="chart-counts">
              <li className="chart-count">
                <label>Ratings: </label>
                <span>{ratingsCount}</span>
              </li>
              <li className="chart-count">
                <label>Users: </label>
                <span>{usersCount}</span>
              </li>
            </ul>
            <DateRange
              handleRangeChange={handleRangeChange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <Bar data={ratingsData} options={options} ref={chartRef} />
          <div className="chart-controls">
            <div className="btn-group btn-group-sm" role="group">
              <input
                className="btn-check"
                type="radio"
                name="interval"
                id="day"
                value="day"
                checked={interval === "day"}
                onChange={e => setInterval(e.target.value)}
              />
              <label className="btn btn-outline-primary" htmlFor="day">
                Day
              </label>
              <input
                className="btn-check"
                type="radio"
                name="interval"
                id="week"
                value="week"
                checked={interval === "week"}
                onChange={e => setInterval(e.target.value)}
              />
              <label className="btn btn-outline-primary" htmlFor="week">
                Week
              </label>
              <input
                className="btn-check"
                type="radio"
                name="interval"
                id="month"
                value="month"
                checked={interval === "month"}
                onChange={e => setInterval(e.target.value)}
              />
              <label className="btn btn-outline-primary" htmlFor="month">
                Month
              </label>
            </div>
            <CSVLink
              data={data}
              headers={[
                {
                  label: "Date",
                  key: "date",
                },
                {
                  label: "High Value Ratings Count",
                  key: "high_value_ratings_count",
                },
                {
                  label: "Ratings Count",
                  key: "ratings_count",
                },
                {
                  label: "Users Count",
                  key: "users_count",
                },
              ]}
              filename={`users-and-ratings"-${startDate}-${endDate}-${interval}.csv`}
              className="btn btn-light btn-sm"
            >
              Export to CSV
            </CSVLink>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default DailyRatings
